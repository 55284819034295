
    // Обработчик события отправки формы
    document.querySelector(".form").addEventListener("submit", function(event) {
      event.preventDefault(); // Prevent default form submission

      var th = this;
      var formData = new FormData(th);

      fetch("mail.php", {
        method: "POST",
        body: formData
      })
      .then(function(response) {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then(function(data) {
        document.querySelector('.modal-sps').classList.add('sps-show');
        document.querySelector('.js-modal-overlay').classList.add('is-show');
        setTimeout(function() {
          th.reset();
        }, 1000);
      })
      .catch(function(error) {
        console.error('There has been a problem with your fetch operation:', error.message);
      });
    });
    try {
    // Перемещаем обработчик события клика по кнопке для закрытия модального окна внутрь DOMContentLoaded
      document.querySelector('.close-modal').addEventListener("click", function(event) {
        document.querySelector('.modal-sps').classList.remove('sps-show');
        document.querySelector('.js-modal-overlay').classList.remove('is-show');
    });
  } catch (error) {

  }






