const header = document.querySelector('.header');
const menuScroll = document.querySelector('.header__wrap');

window.addEventListener('scroll', function () {
  // Check if the page is scrolled to the top
  if (window.scrollY < 25) {
    header.style.background = ""; // remove background color
    // header.style.marginTop = ""; // remove margin top
    // menuScroll.style.padding = "0";

  } else {
    header.style.background = "linear-gradient(180deg, #161519 36%, rgba(22, 21, 25, 0.00) 100%)";
    // header.style.marginTop = "0";
    // menuScroll.style.padding = "0";
  }
});
